<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage careers</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Careers
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn
                  v-if="checkIsAccessible('career', 'create')"
                  @click="createJob()"
                  class="mt-4 btn btn-primary mr-1"
                  dark
                >
                  <i class="fa fa-plus"></i>
                  Add new job
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Title"
                    type="text"
                    v-on:keyup.enter="getCareers"
                    dense
                    outlined
                    v-model="search.title"
                  ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="4">
                  <v-select
                    v-on:keyup.enter="getCareers"
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    clearable
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn
                    @click="getCareers()"
                    class="btn btn-primary w-35 btn-search"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="wrap-column px-3">Title</th>
                    <th class="wrap-column px-3">Closing date</th>
                    <th class="wrap-column px-3">Total application</th>
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(career, index) of careers" :key="index">
                    <td class="wrap-column px-3 py-2">
                      <a href="#" @click="getApplications(career.id)" class="mr-2">
                        {{ career.title }}</a
                      >
                      &nbsp;&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="career.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>
                    <td class="wrap-column px-3 py-2">
                      <span class="font-weight-medium">
                        {{ career.expiry_date_format }}
                      </span>
                    </td>
                    <td class="wrap-column px-3 py-2">
                      <span class="badge badge-info">{{
                        career.career_application_count
                      }}</span>
                    </td>

                    <td class="px-2 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown"
                          dropright
                          no-caret
                        >
                          <template v-slot:button-content>
                            <a href="#" class="" data-toggle="dropdown">
                              <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                              <!--begin::Svg Icon-->
                              <i class="flaticon-more-1"></i>
                              <!--end::Svg Icon-->
                              <!--              </span>-->
                            </a>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover">
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('career', 'show')"
                            >
                              <a
                                href="#"
                                class="navi-link"
                                @click="getApplications(career.id)"
                              >
                                <span class="navi-icon">
                                  <i class="fa fa-cog"></i>
                                </span>
                                <span class="navi-text">Applications</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('career', 'edit')"
                            >
                              <a
                                href="#"
                                class="navi-link"
                                @click="editCareer(career.id)"
                              >
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('career', 'delete')"
                            >
                              <a
                                href="#"
                                class="navi-link"
                                @click="deleteCareer(career.id)"
                              >
                                <span class="navi-icon">
                                  <i class="fa fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->

                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="careers.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>
                        No data available to display.
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CareerService from "@/core/services/career/CareerService";

const career = new CareerService();

export default {
  name: "Careers",
  components: {},
  data() {
    return {
      careers: [],
      loading: false,
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      search: {
        active: "active",
      },
    };
  },
  mounted() {
    this.getCareers();
  },
  methods: {
    getCareers() {
      this.loading = true;
      career.paginate(this.search).then((response) => {
        this.careers = response.data.data;
        this.loading = false;
      });
    },
    editCareer(id) {
      this.$router.push({
        name: "career-update",
        params: { id: id },
      });
    },

    getApplications(id) {
      this.$router.push({
        name: "career-application",
        params: { id: id },
      });
    },
    createJob() {
      this.$router.push({
        name: "career-new",
      });
    },
    deleteCareer(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
      });
    },
  },
};
</script>
